import React, { createContext, useRef, useState } from 'react';

import { appConfig } from '../config';

// create context
export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteCommentModalOpen, setIsDeleteCommentModalOpen] =
    useState(false);
  const [isUpdateCommentModalOpen,setIsUpdateCommentModalOpen]=
  useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [refreshSearch, setRefreshSearch] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('');
  const resultsPerPage = appConfig.defaultPagingSize;
  const [limitData, setLimitData] = useState(appConfig.defaultPagingSize);
  const [isBulkDrawerOpen, setIsBulkDrawerOpen] = useState(false);
  const [lang, setLang] = useState('');
  const [time, setTime] = useState('');
  const [sortedField, setSortedField] = useState('');
  const [sortedDirection, setSortedDirection] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [searchTextByName, setSearchTextByName] = useState(null);
  const [isSearchTextByNameFocused, setIsSearchTextByNameFocused] = useState(false);
  
  const [zone, setZone] = useState('');
  const [status, setStatus] = useState('');
  const [source, setSource] = useState('');
  const [category, setCategory] = useState(null);
  const [openQuotationFromCustomer, setOpenQuotationFromCustomer] = useState(0);
  const searchRef = useRef('');

  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeDrawer = (statusCode = '') =>
    statusCode === '' || statusCode === 200 ? setIsDrawerOpen(false) : '';

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const openDeleteCommentModal = () => setIsDeleteCommentModalOpen(true);
  const closeDeleteCommentModal = () => setIsDeleteCommentModalOpen(false);

  const openUpdateCommentModal = () => setIsUpdateCommentModalOpen(true);
  const closeUpdateCommentModal = () => setIsUpdateCommentModalOpen(false);

  const closeBulkDrawer = () => setIsBulkDrawerOpen(false);
  const toggleBulkDrawer = () => setIsBulkDrawerOpen(!isBulkDrawerOpen);

  const handleChangePage = (p) => {
    setCurrentPage(p);
  };

  const handleSubmitForAll = (e) => {
    e.preventDefault();
    if (!searchRef.current.value) return setSearchText(null);
    setCategory(null);
    setSearchText(searchRef.current.value);
  };

  // const value = useMemo(
  //   () => ({
  //     isSidebarOpen,
  //     toggleSidebar,
  //     closeSidebar,
  //     isDrawerOpen,
  //     toggleDrawer,
  //     closeDrawer,
  //     setIsDrawerOpen,
  //     isModalOpen,
  //     toggleModal,
  //     closeModal,
  //     isUpdate,
  //     setIsUpdate,
  //   }),

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [isSidebarOpen, isDrawerOpen, isModalOpen, isUpdate]
  // );

  return (
    <SidebarContext.Provider
      value = {{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        setIsDrawerOpen,
        isModalOpen,
        toggleModal,
        closeModal,
        openModal,
        isUpdate,
        setIsUpdate,
        closeBulkDrawer,
        isBulkDrawerOpen,
        toggleBulkDrawer,
        lang,
        setLang,
        currentPage,
        setCurrentPage,
        handleChangePage,
        searchText,
        setSearchText,
        setSearchTextByName,
        isSearchTextByNameFocused,
        setIsSearchTextByNameFocused,
        searchTextByName,
        category,
        setCategory,
        searchRef,
        handleSubmitForAll,
        status,
        setStatus,
        zone,
        setZone,
        time,
        setTime,
        sortedField,
        setSortedField,
        sortedDirection,
        setSortedDirection,
        source,
        setSource,
        resultsPerPage,
        limitData,
        setLimitData,
        currentScreen,
        setCurrentScreen,
        isDeleteCommentModalOpen,
        openDeleteCommentModal,
        closeDeleteCommentModal,
        isUpdateCommentModalOpen,
        openUpdateCommentModal,
        closeUpdateCommentModal,

        openQuotationFromCustomer,
        setOpenQuotationFromCustomer,
        refreshSearch, setRefreshSearch
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
